.roundedDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* additional styles */
  width: 40px;
  height: 40px;
  background-color: #e0e0e0;
  margin-right: 2px;
}

.userDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-top: 5px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  width: 120px;
  height: 55px;
  padding: 10px;
}

.userOuterDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.popOverTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: roboto;
  font-size: 18px;
}

.popOverContent {
  border-radius: 10px;
  font-family: roboto;
  font-size: 16px;
  cursor: pointer;
  padding: 4px;
}

.userIcon {
  margin: 0 10px 0 2px;
}
