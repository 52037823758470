#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.outerRow {
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-row {
  height: 100%;
}

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height */

  /* Gray 1 */

  color: #333333;
}

.right-column {
  background: linear-gradient(
    90deg,
    rgba(0, 7, 36, 1) 1%,
    rgba(25, 110, 219, 1) 19%,
    rgba(0, 212, 255, 0.8911939775910365) 65%
  );
  min-width: 50%;
  border-radius: 0 10px 10px 0;
}

.custom-row {
  border-radius: 20px;
}

.login-outer-div {
  height: 100vh;
  /* padding: 150px 50px 20px 50px; */
  padding: 40px;
  background: #f8f9f8;
}

.form-outer-div {
  display: flex;
  z-index: 3;
}

.form-left-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px;
  min-width: 50%;
  background: #ffff;
  border-radius: 10px 0 0 10px;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #828282;
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #828282;
}

.input {
  height: 60px;
  font-size: 20px;
}

.inputs-padding {
  padding: 0px 50px;
}

.input-wrapper {
  margin-top: 20px;
}

.remember-me-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.font-16 {
  font-size: 16px;
}

.login-button {
  min-width: '100%';
  height: '50px';
  font-size: '20px';
}
