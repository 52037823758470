.mainTitle {
  display: flex;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* line-height: 23px; */
  justify-content: left;

  color: #000000;
}
