body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.outer-div {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #c44242;
}
.inner-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: #fff;
}

.custom-steps .ant-steps-item-tail {
  display: none !important;
}

.custom-steps .ant-steps-item .ant-steps-item-tail {
  display: none !important;
}

.ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: transparent !important;
}

.ant-steps-item-custom .ant-steps-item-container {
  border-bottom: none;
}

.ant-steps > .ant-steps-navigation > .ant-steps-item::before {
  background-color: transparent !important;
}

.ant-steps-item::before {
  border-bottom-color: transparent !important;
}

.ant-steps.ant-steps-navigation .ant-steps-item::before {
  background-color: transparent !important;
}

:where(
    .css-dev-only-do-not-override-yp8pcc
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
:where(
    .css-dev-only-do-not-override-yp8pcc
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Remove the existing height and width */
  width: unset;
  height: unset;

  /* Add new height and width */
  width: 400px;
  height: 300px;

  /* The rest of the CSS properties remain the same */
  margin-inline-end: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.02);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.custom-accordion .ant-collapse-header .ant-collapse-arrow {
  float: right !important;
  margin-top: 4px; /* Adjust this value as needed */
}

.ant-layout-sider > .ant-layout-sider-dark > .ant-layout-sider-has-trigger {
  min-width: 300px !important;
}
