.outerRow {
  margin-bottom: 5px;
}

.subTitle {
  display: flex;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  /* Gray 3 */

  color: #828282;
  justify-content: left;
}
