.listSectionOuter {
  margin-top: 5px;
}

.modalTitle {
  /* width: 130px;
    height: 23px;
    left: 30px;
    top: 30px; */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  color: #000000;
}

.modalSubTitle {
  /* width: 208px;
    height: 16px;
    left: 30px;
    top: 60px; */

  /* Univiser-support-module/content */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  /* Gray 3 */

  color: #828282 !important;
}

.titleLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */
  margin-bottom: 5px;
  color: #212529;
}

.valueLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #545454;
}

.subDetailDiv {
  margin-top: 10px;
  margin-bottom: 25px;
}

.detailDiv {
  margin-top: 15px !important;
}

.acceptButton {
  width: 77px;
  height: 37px;
  color: #fff;
  background: #005994 !important;
  border-radius: 6px !important;
}

.rejectButton {
  width: 77px;
  height: 37px;
  color: #fff;
  background: #f15642 !important;
  border-radius: 6px !important;
  font-weight: 600;
}

.commentBox {
  border: 1px solid #C5C5C5;
  border-radius: 5px;
  padding: 10px;
}
